import React from 'react'
import withStyles from 'react-jss'
import {StyleRoot} from 'radium'
import {connect} from 'react-redux'
import Logo from '../../assets/images/template/logo.png'
import {Alert} from 'reactstrap'
import Axios from 'axios'
import SimpleReactValidator from 'simple-react-validator'
import {Link} from 'react-router-dom'

const styles = {}

const mapStateToProps = state =>{
    return state
}

class contactUs extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            alert: {
                color: null,
                message: null,
                present: null
            },
            name: '',
            email: '',
            phone: '',
            details: ''
        }
        this.validator = new SimpleReactValidator();
    }
    componentDidMount(){

    } 
    componentWillUnmount(){

    }

    handleSubmit = () => {
        if(this.validator.allValid()){
            this.submitForm()
        }
        else{
            this.validator.showMessages();
            this.forceUpdate()
        }
    }

    submitForm = () => {
        Axios({
            method: 'post',
            url: 'https://api.clickficks.com/quote/postQuoteRequest',
            data: {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                details: this.state.details
            }
        })
        .then(res=>{
            var alert = Object.assign({}, this.state.alert);
            alert.present = true;
            alert.color = 'success';
            alert.message = 'Submitted Successfully! Someone from Clickficks will be in contact with you soon!'
            this.setState({
                alert
            })
        })
        .catch(err=>{
            var alert = Object.assign({}, this.state.alert);
            alert.present = true;
            alert.color = 'danger';
            alert.message = 'Failed to submit! If this keeps happening please give us a call! We are more than happy to serve you!';
            this.setState({
                alert
            })
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.currentTarget.id]: event.currentTarget.value
        })
    }

    render(){
        
        return(
            <StyleRoot>
                <div>
                    <section className="iq-breadcrumb">
                        <div className="iq-breadcrumb-info">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12 col-md-7 align-self-center">
                                <h2 className="iq-fw-8 mb-3">Contact Us</h2>
                                <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                                </ol>
                                </nav>
                            </div>
                            <div className="col-md-5">
                                <img src={Logo} className="img-fluid" alt="Clickficks Logo" />
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="iq-breadcrumb-img">
                            <img src="images/breadcrumb/02.png" className="img-fluid breadcrumb-two" alt="image" />
                        </div>
                    </section>
                    {/* Breadcrumb END */}
                    {/* Main Content */}
                    <div className="main-content">
                        {this.state.alert.present ?
                            <Alert color={this.state.alert.color}>{this.state.alert.message}</Alert> : <div/>
                        }
                        <section className="iq-contact-us pb-0">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12">
                                <div className="section-title mb-4">
                                <h2 className="title iq-fw-8">Contact Us</h2>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-12">
                                <div className="project-form mb-3">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control contact-first-name" placeholder="Name" id='name' value={this.state.name} onChange={this.handleChange} />
                                        {this.validator.message('Name', this.state.name, 'required')}
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control contact-phone" placeholder="Phone Number" id='phone' value={this.state.phone} onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control contact-email" placeholder="Email" id='email' value={this.state.email} onChange={this.handleChange}/>
                                        {this.validator.message('email', this.state.email, 'required|email')}
                                    </div>
                                    </div>
                                    <div className="col-lg-6">
                                    <div className="form-group">
                                        <textarea className="form-control contact-message" placeholder="Type Your Requirements" defaultValue={""} id='details' value={this.state.details} onChange={this.handleChange} />
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <a className="slide-button button contact-us-button" href='javascript:void(0)' onClick={this.handleSubmit}>
                                <div className="first">Submit</div>
                                <div className="second">Submit</div>
                                </a>
                            </div>
                            </div>
                        </div>
                        </section>
                        <section>
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-12 ">
                                <div className="row">
                                <div className="col-lg-6">
                                    <h2 className="title iq-fw-8">Get in Touch</h2>
                                    <p>Our main location is not open for walk ins, however a meeting time can be scheduled by calling the number below</p>
                                    <div className="iq-address mt-3">
                                    <div className="media ">
                                        <div className="mr-3 contact-icon float-left"><i className="fa fa-map-marker" aria-hidden="true" /></div>
                                        <div className="media-body">
                                        <a href='https://maps.google.com/maps?ll=29.751504,-95.367712&z=16&t=m&hl=en&gl=US&mapclient=embed&daddr=1617%20Fannin%20St%20Houston%2C%20TX%2077002@29.751504,-95.367712'>
                                            <p>1617 Fannin St 3016 <br /><span className="iq-fw-5">Houston, TX 77002 USA</span></p>
                                        </a>
                                        </div>
                                    </div>
                                    <div className="media ">
                                        <div className="mr-3 contact-icon float-left"><i className="fas fa-envelope " /> </div>
                                        <div className="media-body">
                                        <p className="iq-fw-5">
                                            <a href='mailto:tyler@clickficks.com'>
                                                tyler@clickficks.com
                                            </a>
                                        </p>
                                        </div>
                                    </div>
                                    <div className="media ">
                                        <div className="mr-3 contact-icon float-left"><i className="fa fa-phone" /> </div>
                                        <div className="media-body">
                                        <p className="iq-fw-5">
                                            <a href='tel:7132692011'>+1 713 269 2011</a>
                                        </p>
                                        </div>
                                    </div>
                                    <div className="social-media mt-4 ">
                                        <ul className="social">
                                        <li><a href="https://www.facebook.com/clickficks/"><i className="fab fa-facebook-f " /></a></li>
                                        <li><a href="https://twitter.com/clickficks_"><i className="fab fa-twitter " /></a></li>
                                        <li><a href="https://www.linkedin.com/in/tyler-c-435455b8/"><i className="fab fa-linkedin" /></a></li>
                                        {/*<li><a href="#"><i className="fab fa-youtube" /></a></li>*/}
                                        <li><a href="https://www.instagram.com/clickficks_/"><i className="fab fa-instagram" /></a></li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <iframe className="w-100 contact-ifream" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3463.9035655594703!2d-95.36990068431273!3d29.751503981989764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640bf3ddf96907f%3A0x448aee262d128897!2s1617+Fannin+St%2C+Houston%2C+TX+77002!5e0!3m2!1sen!2sus!4v1561909286619!5m2!1sen!2sus" allowFullScreen />
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>
                    </div>
                </div>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps)(withStyles(styles)(contactUs))