import {SAVE_PORTFOLIO} from '../../constants/portfolio/index'

const initalState = [];

const portfolioReducer = (state = initalState, action) => {
    switch(action.type){
        case SAVE_PORTFOLIO:
            return {...state,
                portfolio: action.data
            }
        default:
            return state
    }
}

export default portfolioReducer