import React from 'react'
import {StyleRoot} from 'radium'
import {connect} from 'react-redux'
import { CarouselProvider, Slider, Slide} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {Link, withRouter} from 'react-router-dom'
const mapStateToProps = state => {
    return state
}

class recentBlog extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            width: null,
            title: props.title ? props.title : ''
        };
    }
    componentDidMount(){
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.updateDimensions()
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }
    strip(html){
        var doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }
    updateDimensions = () =>{
        this.setState({
            width: window.innerWidth
        })
    }
    blogClick = (event) => {
        alert('Code ran');
        console.log('code ran');
        var slug = event.currentTarget.id;
        this.props.location.push(`/blog/${slug}`);
    }
    render(){
        return(
            <StyleRoot>
                <section className="iq-blogs position-relative">
                    <div className="container">
                        <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="section-title">
                            <p className="mb-0 text-uppercase iq-fw-5 iq-ls-2">Blog</p>
                            <h2 className="title iq-fw-8">{typeof(this.state.title) === 'undefined' || this.state.title === '' ? 'Learn Tech': this.state.title}</h2>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="owl-carousel" data-autoplay="true" data-loop="true" data-nav="false" data-dots="false" data-items={3} data-items-laptop={3} data-items-tab={2} data-items-mobile={1} data-items-mobile-sm={1}>
                            
                            <CarouselProvider
                                naturalSlideWidth={50}
                                naturalSlideHeight={this.state.width >= 400 ? 90 : 100}
                                totalSlides={typeof(this.props.BlogReducer.posts) != 'undefined' ? this.props.BlogReducer.posts.length : 4}
                                isPlaying={true}
                                visibleSlides={this.state.width <= 1024 ? this.state.width <= 768 ? 1:  2 : 3}
                            >
                                {typeof(this.props.BlogReducer.posts) != 'undefined' && this.props.BlogReducer.posts.length > 0 ?
                                    <Slider>
                                        {this.props.BlogReducer.posts.map((p, index)=>{
                                        return(
                                            <Slide index={index} key={`slide-${index}`} >
                                                <div className="item">
                                                    <div className="main-blog">
                                                    <div className="blog-img">
                                                        {typeof(p.images) != 'undefined' && p.images.length > 0 ?  <img src={p.images[0].url} className="img-fluid" alt={p.images[0].alt} /> : <span/> }
                                                       
                                                    </div>
                                                    <div className="blog-detail">
                                                        <div className="main-color iq-fw-8" >{p.category}</div>
                                                        <a href={`/blog/${p.slug}`} id={p.slug}>
                                                            <h5 className="mt-1 iq-fw-8">{p.title}</h5>
                                                        </a>
                                                        <p className="mb-0">{this.strip(p.html).slice(0, 75) + '...'}</p>
                                                        <div className="blog-info">
                                                        <img src={p.authorImg} className="img-fluid rounded-circle mr-3 user-img" alt={`Photo of ${p.fullName}`} /><span className="iq-fw-8 font-c iq-font-18">{p.authorName}</span>
                                                        {/*
                                                            <ul className="d-inline-block float-right">
                                                                <li className="d-inline-block"><a href="#"><i className="far fa-thumbs-up" /> <span className="iq-fw-8 iq-font-14">90</span></a></li>
                                                                <li className="d-inline-block"><a href="#"><i className="far fa-comment-alt" /> <span className="iq-fw-8 iq-font-14">120</span></a></li>
                                                            </ul>
                                                        */}
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </Slide>
                                        )
                                        })}
                                    </Slider>   : <div/>
                                }
                            </CarouselProvider>
                           
                           
                            
                            
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </section>
            </StyleRoot>
        )
    }
}

export default withRouter(connect(mapStateToProps)(recentBlog))