import {SAVE_BLOG_CONTENT, SAVE_CURRENT_BLOG} from '../../../redux/constants/blog/index'

export const saveBlogContents = data => ({
    type: SAVE_BLOG_CONTENT,
    data: data
})

export const saveCurrentBlog = data => ({
    type: SAVE_CURRENT_BLOG,
    data: data
})
