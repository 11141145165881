import React from 'react'
import withStyles from 'react-jss'
import {Row, Col, Button} from 'reactstrap'
import {Link} from 'react-router-dom'
import BackgroundImage from '../../../assets/images/quote/workplace.jpg'

const styles = {
    
    rootContainer: {
        marginTop: '100px',
        height: 'calc(100vh - 100px)',
        minHeight: '800px',
        position: 'relative',
        background: '#04C485',
        '@media (max-width: 768px)':{
            marginTop: '75px',
            minHeight: '1vh'
        },
    },
    innerBox: {
        height: '90%',
        width: '90%',
        position: 'relative',
        top: '5%',
        left: '5%',
        border: '25px solid white',
        padding: '30px',
        boxShadow: '5px 5px 20px 5px #8655E9',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: '20% 20%',
        background: '#04C485',

        
        '@media (max-width: 469px)':{
            top: '0%',
            left: '0%',
            padding: '30px',
            height: '100%',
            width: '100%',
            border: '0px',
            padding: '0px',
            backgroundImage: `url(${BackgroundImage})`,
            backgroundPosition: '20% 20%',
            background: '#04C485',
            
        }
    },
    innerBoxBg: {
        background: '#8655E9',
        height: '100%',
        width: '100%',
        position: 'absolute',
        left: '0',
        top: '0',
        opacity: '.8'
        
    },
    mainTitle: {
        color: '#ffffff',
        fontWeight: '600 !important',
        fontSize: '7em !important',
        lineHeight: '1',
        fontFamily: 'sans-serif',
        width: '92%',
        position: 'absolute',
        textAlign: 'center',
        left: '4%',
        '@media (max-width: 1400px)':{
            fontSize: '6em !important'
        },
        '@media (max-width: 1200px)':{
            fontSize: '5em !important'
        },
        '@media (max-width: 768px)':{
            fontSize: '4em !important'
        },
        '@media (max-width: 612px)':{
            fontSize: '3em !important'
        },
        '@media (max-width: 469px)':{
            fontSize: '2em !important',
            paddingTop: '10px'
        }

    },
    btnRow:{
       position: 'absolute',
       bottom: '20%',
       left: '3%',
       width: '94%',
       padding: '0',
       '@media (max-width: 768px)':{
            width: '100%',
        }
    },
    btn: {
        background: '#ffffff',
        
        width: '94%',
        height: '60px', 
        position: 'absolute',
        left: '3%',
        fontSize: '2em',
        fontWeight: '600',
        border: '0px !important',
        '@media (max-width: 768px)':{
            padding: '0px',
            width: '90%',
            position: 'relative',
            fontSize: '1em',
            marginTop: '15px'
        },
        '@media (max-width: 930px)':{
            fontSize: '1.3em !important'
        }
    },
    btnWeb: {
        color: '#8655E9',
        '&:hover': {
            background: '#04C485',
            color: '#8655E9'
        },
    },
    btnMarketing:{
        color: '#04C485',
        '&:hover': {
            background: '#8655E9',
            color: '#04C485'
        }
    },
    green: {
        color: '#04C485'
    }
    
}

class newCallToAction extends React.Component{
    
    render(){
        const {classes} = this.props;
        return(
            <div className={classes.rootContainer}>
                <div className={classes.innerBox}>
                    <div className={classes.innerBoxBg}></div>
                        <div className={classes.innerBoxContent}>
                            <h1 className={classes.mainTitle}>
                                <b>WE CREATE CUSTOM BUSINESS <span className={classes.green}>WEBSITES</span></b>
                            </h1>
                            <div className={classes.btnRow}>
                                    <Row>
                                        <Col md={6} xs={12}>
                                            <Link to='/quote'>
                                                <Button className={`${classes.btn} ${classes.btnWeb}`}>
                                                    QUOTE NOW
                                                </Button>
                                            </Link>
                                        </Col>
                                        <Col md={6} xs={12}>
                                            <Link to='/contact-us'>
                                                <Button className={`${classes.btn} ${classes.btnMarketing}`}>
                                                   Contact Us
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                            </div>
                        </div>
                 
               </div>
            </div>
        )
    }
}

export default withStyles(styles)(newCallToAction)