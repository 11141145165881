import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {StyleRoot} from 'radium'
import {setLoadingStatus} from '../../redux/actions/loading/loading'
import Moment from 'moment'
import InnerHTML from 'dangerously-set-inner-html'
import {Link} from 'react-router-dom'
import Blog from '../components/home/recentBlogs'

//assets
import PortfolioImage from '../../assets/images/portfolio/portfolio.svg'
import withStyles from 'react-jss'

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({

})

const styles = {
    portfolioImage: {
        maxHeight: '150px',
        paddingBottom: '15px'
    },
    urlLink:{
        fontSize: '0.8em'
    }
}


class portfolioDetail extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            path: '',
            currentPortfolioItem: null
        }
    }
    componentDidMount(){
        this.props.history.listen(this.onRouteChange)
        this.loadPage()
        

    }
 
     onRouteChange = (location) => {         
         //ensure route is in service path
         if(String(location.pathname).slice(0,10) === '/portfolio'){
             this.loadPage(String(location.pathname).slice(11))
         }
     }
     loadPage = (nextProps) => {
        this.findPageData(nextProps).then(pageData=>{
            this.setState({
                currentPortfolioItem: pageData
            })
        })
        .catch(err=>{
            //send to 404
            console.log(err, 'Unable to find page data')
            this.props.history.push('/404')
        })
     }

    findPageData = (nextProps) => {
        console.log('nextProps',nextProps)
        return(
            new Promise((resolve, reject)=>{
                var slug = nextProps ? nextProps : this.props.match.params.slug;
                this.props.PortfolioReducer.portfolio.forEach((p, index)=>{
                    if(p.slug === slug){
                        resolve(p)
                    }
                })
                for(var i=0; i < this.props.PortfolioReducer.portfolio.length; i++){
                    if(this.props.PortfolioReducer.portfolio[i].slug === slug){
                        resolve(this.props.PortfolioReducer.portfolio[i])
                    }
                    else{
                        if(i + 1 === this.props.PortfolioReducer.portfolio.length){
                            reject('Page data not found! Please try refreshing the page')
                        }
                    }
                }
            })
        )
    }
    render(){
        const {classes} = this.props;
        return(
            <StyleRoot>
                {this.state.currentPortfolioItem ?
                <div>
                <section className="iq-breadcrumb">
                    <div className="iq-breadcrumb-info">
                    <div className="container">
                        <div className="row">
                        <div className="col-sm-12  col-md-7 align-self-center">
                            <h2 className="iq-fw-8 mb-3">{this.state.currentPortfolioItem.name}</h2>
                            <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/portfolio'>Portfolio</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{this.state.currentPortfolioItem.name}</li>
                            </ol>
                            </nav>
                        </div>
                        <div className="col-md-5">
                        <img src={PortfolioImage} className="img-fluid" alt='Portfolio Header Graphic' className={classes.portfolioImage} />
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="iq-breadcrumb-img">
                    <img src="images/breadcrumb/02.png" className="img-fluid breadcrumb-two" alt="image" />
                    </div>
                </section>
                {/* Breadcrumb END */}
                {/* Main Content */}
                <div className="main-content">
                    {/* Portfolio Detail */}
                    <section className="iq-portfolio-detalis pb-0">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-img">
                            <img src={this.state.currentPortfolioItem.images[0].imgSrc} className="img-fluid" alt={this.state.currentPortfolioItem.images[0].imgAlt} />
                            </div>
                        </div>
                        </div>
                        <div className="row ">
                        <div className="col-lg-3 col-md-4">
                            <div className="section-title mb-3">
                            <h3 className="title  mb-0">{Moment(this.state.currentPortfolioItem.date).format('DD MMMM YYYY')}</h3>
                            </div>
                            <div className="portfolio-info">
                            <div className="media mb-3">
                                <div className="mr-3"><i className="contact-icon fa fa-check-circle text-green" /></div>
                                <div className="media-body">
                                <h6 className="mt-0 text-uppercase iq-fw-8">Name:</h6>
                                <p>{this.state.currentPortfolioItem.name}</p>
                                </div>
                            </div>
                            <div className="media  mb-3">
                                <div className="mr-3"><i className="contact-icon fa fa-check-circle text-green" /></div>
                                <div className="media-body">
                                <h6 className="mt-0 text-uppercase iq-fw-8">Company:</h6>
                                <p>{this.state.currentPortfolioItem.company}</p>
                                </div>
                            </div>
                            <div className="media  mb-3">
                                <div className="mr-3"><i className="contact-icon fa fa-check-circle text-green" /></div>
                                <div className="media-body">
                                <h6 className="mt-0 text-uppercase iq-fw-8">URL:</h6>
                                <a href={this.state.currentPortfolioItem.url} className={classes.urlLink}>Click Here To Open</a>
                                </div>
                            </div>
                            <div className="social-media ">
                                <ul className="social">
                                <li><a href="#"><i className="fab fa-facebook-f " /></a></li>
                                <li><a href="#"><i className="fab fa-twitter " /></a></li>
                                <li><a href="#"><i className="fab fa-linkedin" /></a></li>
                                </ul>
                            </div>
                            <div className="clearfix" />
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <div className="section-title mb-2">
                                <h2 className="title mb-0">{this.state.currentPortfolioItem.headerText}</h2>
                            </div>
                            <InnerHTML html={this.state.currentPortfolioItem.desc}/>
                            <Link className="slide-button button mt-3 " to='/portfolio'>
                                <div className="first">Explore All</div>
                                <div className="second">Explore All</div>
                            </Link>
                        </div>
                        </div>
                    </div>
                    <div className="iq-recentwork">
                        <div className="container">
                        <div className="row ">
                            <div className="col-sm-12 text-center">
                            <div className="section-title">
                                <p className="mt-2 text-uppercase iq-fw-3 iq-ls-3">VIEW OTHER PORTFOLIO ITEMS</p>
                                <h3 className="title iq-fw-8">Other Work</h3>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="owl-carousel" data-autoplay="true" data-loop="true" data-nav="true" data-dots="false" data-items={3} data-items-laptop={3} data-items-tab={2} data-items-mobile={1} data-items-mobile-sm={1} data-margin={30}>
                                {this.props.PortfolioReducer.portfolio.map(p=>{
                                    return(
                                        <Link to={`/portfolio/${p.slug}`} key={`portfolioLink-${p.id}`}>
                                            <div className="item">
                                                <div className="iq-portfolio">
                                                    <img src={p.images[0].imgSrc} className="img-fluid" alt="image" />
                                                    <div className="portfolio-info">
                                                    <span className="text-black">{p.name}</span>
                                                    <div className="text-uppercase text-gray float-right">Website</div>
                                                    </div>
                                                </div>
                                                </div>
                                        </Link>
                                    )
                                })}
                            </div>
                            </div> 
                        </div>
                        </div>
                    </div>
                    </section>
                    {/* Portfolio Detail END */}
                   
                    <Blog/>
                    
                </div>
                </div> : <div/>
            }

            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(portfolioDetail)))