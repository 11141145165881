import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {StyleRoot} from 'radium'
import {Row, Col, FormGroup, Input, Container} from 'reactstrap'
import {saveEmail} from '../../../redux/actions/quote/index'

const styles  = {
    section:{
        padding: '15px !important'
    },
    formLabel: {
        color: 'black',
        fontWeight: '600',
        fontSize: '1.2em'
    }
}

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    saveEmail: email => dispatch(saveEmail(email))
})

const AnimationStyles = require('../../../utils/animations')

class email extends React.Component{


    constructor(props){
        super(props);
        this.state = {
            animations: {}
        }
    }

    componentWillMount(){
        this.mountAnimations()
    }

    handleChange = (event) => {
        this.props.saveEmail(event.currentTarget.value)
    }

    mountAnimations = () =>{
        this.setState({
            animations: AnimationStyles.styles.zoom_in_down
        })
    }

    render(){
        const {classes} = this.props;
        
        return(
            <StyleRoot>
                <Container>
                            <section style={this.state.animations} className={classes.section}>
                                <Row>
                                    <Col xs={12} md={12}>
                                       
                                            <FormGroup className={classes.nameInputFormGroup}>
                                                    <div className="form-group mb-0">
                                                        <Input type="text" className="form-control position-relative subscription-email  mb-0" id='E-Mail' value={this.props.QuoteReducer.email} onChange={this.handleChange} placeholder='Enter your E-Mail' />
                                                    </div>
                                            </FormGroup> 
                                    </Col>
                                </Row> 
                            </section>
                        </Container>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(email))