import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {StyleRoot} from 'radium'
import Logo from '../../assets/images/template/logo.png'
import {saveCurrentBlog} from '../../redux/actions/blog/index'
import InnerHTML from 'dangerously-set-inner-html'
import Moment from 'moment'
import {Link} from 'react-router-dom'
import RecentPosts from '../components/home/recentBlogs'
import Axios from 'axios';
import CallToAction from '../components/home/newCallToAction'

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    saveCurrentBlog: data => dispatch(saveCurrentBlog(data)),
})

const styles = {}

class blogDetail extends React.Component{
    constructor(props){
        super(props);
        this.state ={ 
            width: null,
        }
    }

    componentDidMount(){
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.updateDimensions();

        this.loadCurrentBlogInfoServer()
            .then(()=>{
                console.log('Blog data successfully loaded from the server! Will not attempt loading locally!')
            })
            .catch(err=>{
                console.log('Unable to pull the blog data from the server! Will try to load data locally!', err);
                this.loadCurrentBlogInfoLocally()
                    .then(()=>{
                        console.log('Blog data had to be loaded locally after failing to load from server. Local load was successful!')
                    })
                    .catch(err=>{
                        console.log('Tried to load data on both the server and locally. Both methods failed! Triggering a 404', err);
                        this.props.history.push('/404')
                    })
            })
                
            
    }
  
    componentWillUnmount(){
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }
 

    updateDimensions = () =>{
        this.setState({
            width: window.innerWidth
        })
    }
    //this is done locally
    loadCurrentBlogInfoLocally = () => {
        return(
            new Promise((resolve, reject)=>{
                var slug = this.props.match.params.slug;
                console.log('loadCurrentService thinks the slug is: ', slug);
                
                for(var i = 0; i < this.props.BlogReducer.posts.length; i++){
                    if(this.props.BlogReducer.posts[i].slug === slug){
                        return resolve(this.props.saveCurrentBlog(this.props.BlogReducer.posts[i]))
                    }
                    else{
                        if(this.props.BlogReducer.posts.length === i + 1){
                            return reject(`Page slug not found with slug: '${slug}'`)
                        }
                    }
                }
                
            })
        )
    }
    //this code reaches out to the server - this is redundant but google crawlers like it :)
    loadCurrentBlogInfoServer = () => {
        return(
            new Promise((resolve, reject)=>{
                var slug = this.props.match.params.slug;
                Axios({
                    method: 'get',
                    url: `https://api.clickficks.com/blog/getBlogPost/${slug}`
                })
                .then(res=>{
                    resolve(this.props.saveCurrentBlog(res.data))
                })
                .catch(err=>{
                    console.log('Unable to pull the current blogs info from the server! Rejecting promise', err);
                    reject('Unable to pull the current blogs info from the server! Rejecting promise')
                })
            })
        )
    }
    render(){
        const CallToActionLink = (
            <div style={{paddingBottom: '10px'}}>
                <Link to='/quote'>
                    Click here to get a <u><b>FREE</b></u> business consultation for all of your <b>Web Development</b> and <b>Marketing</b> needs!
                </Link>
            </div>
        )
        return(
            <StyleRoot>
                    <div>
                    <section className="iq-breadcrumb">
                        <div className="iq-breadcrumb-info">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12 col-md-7 align-self-center">
                                <h2 className="iq-fw-8 mb-3">Blog</h2>
                                <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to='/'>Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">
                                        <Link to='/blog'>
                                            Blog
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {this.props.BlogReducer.currentBlog.title}
                                    </li>
                                </ol>
                                </nav>
                            </div>
                            <div className="col-md-5">
                                <img src={Logo} className="img-fluid" alt='Clickficks Logo' />
                            </div>
                            </div>
                        </div>
                        </div>
                        
                    </section>
                    {/* Breadcrumb END */}
                    {/* Main Content */}
                    <div className="main-content">
                        <section className="iq-blogs">
                        <div className="container">
                            <div className="row">
                            <div className="col-lg-4 order-lg-2 order-2">
                                <div className="right-side-blog">
                                    {/*     Search Box
                                        <div className="iq-sidebar-widget">
                                            <div className="iq-widget-search position-relative"><a href="javascript:void(0)"> <i className="fa fa-search" /></a>
                                            <input type="search" placeholder="Search" className="form-control placeholder" />
                                            </div>
                                        </div>
                                    */}
                                
                                
                            
                                {/*     Categories & Tags 
                                    <div className="iq-sidebar-widget iq-mt-80">
                                        <h5 className="iq-fw-8 mb-4">Categories</h5>
                                        <ul className="d-block">
                                        <li className="d-block mb-3"><a href="javascript:void(0)" className="iq-fw-5">Digtel Agency<span>8</span></a></li>
                                        <li className="d-block mb-3"><a href="javascript:void(0)" className="iq-fw-5">Creative<span>5</span></a></li>
                                        <li className="d-block mb-3"><a href="javascript:void(0)" className="iq-fw-5">Digital Marketing<span>9</span></a></li>
                                        <li className="d-block mb-3"><a href="javascript:void(0)" className="iq-fw-5">Creative<span>3</span></a></li>
                                        <li className="d-block mb-3"><a href="javascript:void(0)" className="iq-fw-5">Digital Marketing<span>6</span></a></li>
                                        <li className="d-block mb-3"><a href="javascript:void(0)" className="iq-fw-5">Digtel Agency<span>4</span></a></li>
                                        <li className="d-block mb-3"><a href="javascript:void(0)" className="iq-fw-5">Digital Marketing<span>9</span></a></li>
                                        <li className="d-block mb-3"><a href="javascript:void(0)" className="iq-fw-5">Digtel Agency<span>2</span></a></li>
                                        <li className="d-block mb-3"><a href="javascript:void(0)" className="iq-fw-5">Digital Marketing<span>7</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="iq-mt-80">
                                        <h5 className="iq-widget-title  iq-tw-6 mb-4">Tags</h5>
                                        <ul className="iq-tags list-inline">
                                        <li><a href="javascript:void(0)">Marketing</a></li>
                                        <li><a href="javascript:void(0)">Digtel Agency</a></li>
                                        <li><a href="javascript:void(0)">SEO</a></li>
                                        <li><a href="javascript:void(0)">Classic</a></li>
                                        <li><a href="javascript:void(0)">Business</a></li>
                                        <li><a href="javascript:void(0)">Creative</a></li>
                                        </ul>
                                    </div>
                                    
                            */}
                            
                            {this.state.width >= 400 ?
                                <div className="blog-title-img text-center iq-mt-80">
                                    <img src={this.props.BlogReducer.currentBlog.authorImg ? this.props.BlogReducer.currentBlog.authorImg : '' } 
                                        className="img-fluid rounded-circle mb-3" 
                                        alt={typeof(this.props.BlogReducer.currentBlog.images) != 'undefined' ? this.props.BlogReducer.currentBlog.images[0].alt : ''} />
                                    <h5>About Author</h5>
                                    <p className="mb-0">{this.props.BlogReducer.currentBlog.authorDesc}</p>
                                </div> : <div/>
                            }
                                
                            </div>
                                </div><br/>
                            <div className="col-lg-8 order-lg-1 order-2 iq-rmt-40">
                                <div className="blog-img">
                                <img src={typeof(this.props.BlogReducer.currentBlog.images) != 'undefined' ? this.props.BlogReducer.currentBlog.images[0].url : ''} 
                                    className="img-fluid mb-4" 
                                    alt={typeof(this.props.BlogReducer.currentBlog.images) != 'undefined' ? this.props.BlogReducer.currentBlog.images[0].alt : ''} 
                                />
                                <ul className="d-inline-block">
                                    <li className="d-inline-block"><i className="far fa-calendar-alt" />{Moment(this.props.BlogReducer.currentBlog.dateCreated).format('DD MMMM YYYY')}</li>
                                    <li className="d-inline-block">|<span className="ml-3">{this.props.BlogReducer.currentBlog.category}</span></li>
                                </ul>
                                
                                <h3 className="title iq-fw-8 mt-3">{this.props.BlogReducer.currentBlog.title}</h3>
                                {CallToActionLink}
                                <InnerHTML html={this.props.BlogReducer.currentBlog.html}/>
                                {CallToActionLink}
                                <div className="iq-blockquote position-relative ">
                                    <blockquote className="blockquote green-bg double-quotes">
                                    <h5 className="mb-0 text-white">{this.props.BlogReducer.currentBlog.importantText}</h5>
                                    
                                    </blockquote>
                                </div>
                                {/*
                                <div className="blog-finding">
                                    <p>Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor.</p>
                                    <ul className="d-inline-block float-right">
                                    <li className="d-inline-block mr-3"><a href="javascript:void(0)"><i className="far fa-thumbs-up" /> <span className="iq-fw-6">90</span></a></li>
                                    <li className="d-inline-block"><a href="javascript:void(0)"><i className="far fa-comment-alt" /> <span className="iq-fw-6">120</span></a></li>
                                    </ul>
                                </div>
                                <div className="clearfix" />
                                
                                <div className="comments-box position-relative mt-5">
                                    <div className="media">
                                    <img className="mr-3 rounded-circle" src="images/blog/clients/01.jpg" alt="Generic placeholder image" />
                                    <div className="media-body">
                                        <h5 className="mt-0 float-left">Nik Jorden</h5>
                                        <a className="month-detail float-right" href="#"><i className="far fa-calendar-alt" /> 01 January 2019</a>
                                        <div className="clearfix" />
                                        <p>Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat.</p>
                                        <a className="reply-btn text-green iq-font-18" href="#">Reply <i className="fas fa-long-arrow-alt-right" /></a>
                                    </div>
                                    </div>
                                </div>*/}
                                {/*
                                <h2 className="title iq-fw-8 mt-5 mb-3">Comments</h2>
                                <ul className="d-block">
                                    <li className="list-inline-item">
                                    <div className="comments-box position-relative">
                                        <div className="media">
                                        <img className="mr-3 rounded-circle" src="images/blog/clients/02.jpg" alt="Generic placeholder image" />
                                        <div className="media-body">
                                            <h5 className="mt-0 float-left">John Deo</h5>
                                            <a className="month-detail float-right" href="#"><i className="far fa-calendar-alt" /> 01 January 2019</a>
                                            <div className="clearfix" />
                                            <p>Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat.</p>
                                            <a className="reply-btn text-green iq-font-18" href="javascript:void(0)">Reply <i className="fas fa-long-arrow-alt-right" /></a>
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li className="list-inline-item">
                                    <div className="comments-box position-relative mt-5 ml-5">
                                        <div className="media">
                                        <img className="mr-3 rounded-circle" src="images/blog/clients/03.jpg" alt="Generic placeholder image" />
                                        <div className="media-body">
                                            <h5 className="mt-0 float-left">Kips Leo</h5>
                                            <a className="month-detail float-right" href="javascript:void(0)"><i className="far fa-calendar-alt" /> 01 January 2019</a>
                                            <div className="clearfix" />
                                            <p>Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat.</p>
                                            <a className="reply-btn text-green iq-font-18" href="javascript:void(0)">Reply <i className="fas fa-long-arrow-alt-right" /></a>
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li className="list-inline-item">
                                    <div className="comments-box position-relative mt-5">
                                        <div className="media">
                                        <img className="mr-3 rounded-circle" src="images/blog/clients/01.jpg" alt="Generic placeholder image" />
                                        <div className="media-body">
                                            <h5 className="mt-0 float-left">Glen Jax</h5>
                                            <a className="month-detail float-right" href="javascript:void(0)"><i className="far fa-calendar-alt" /> 01 January 2019</a>
                                            <div className="clearfix" />
                                            <p>Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat.</p>
                                            <a className="reply-btn text-green iq-font-18" href="javascript:void(0)">Reply <i className="fas fa-long-arrow-alt-right" /></a>
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                </ul>
                                */}
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>
                        {this.state.width < 400 ?
                                <div className="blog-title-img text-center iq-mt-80">
                                    <img src={typeof(this.props.BlogReducer.currentBlog.authorImg) != 'undefined' ? this.props.BlogReducer.currentBlog.authorImg : ''} 
                                        className="img-fluid rounded-circle mb-3" 
                                        alt={typeof(this.props.BlogReducer.currentBlog.images) != 'undefined' ? this.props.BlogReducer.currentBlog.images[0].alt : ''} />
                                    <h5>About Author</h5>
                                    <p className="mb-0">{this.props.BlogReducer.currentBlog.authorDesc}</p>
                                </div> : <div/>
                        }
                        
                        <RecentPosts title='Web Dev and Marketing'/>
                        
                        
                    </div>
                    

                    </div>
                    
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(blogDetail))