import React from 'react'
import {Link} from 'react-router-dom'
import withStyles from 'react-jss'

const styles = {
  mainMessage:{
    '@media (max-width: 600px)':{
      fontSize: '1.2em',
      marginTop: '-60px'
    }
  }
}

class thankYou extends React.Component{
    render(){
      const {classes} = this.props;
        return(
            <div>

  {/* loading End */}
  <div className="iq-pagenotfound position-relative text-center">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="pagenotfound-info ">
           
            <h2 className={`mb-3 iq-fw-8 ${classes.mainMessage}`}>Thank you for choosing Clickficks!<br/>Someone will reach out to you shortly!
            <br/><br/>
            Note: Consultation is free!</h2>
            <br/>
            
            <Link className="slide-button button" to='/'>
              <div className="first">Back to Home</div>
              <div className="second">Back to Home</div>
            </Link>
          </div>
          <img src="images/others/9.png" className="img-fluid pagenotfound-left" alt="Page Graphic" />
          <img src="images/others/10.png" className="img-fluid pagenotfound-right" alt="Page Graphic" />
        </div>
      </div>
    </div>
    
  </div>

</div>

        )
    }
}

export default withStyles(styles)(thankYou)