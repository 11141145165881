import React from 'react'
import withStyles from 'react-jss'
import InnerHTML from 'dangerously-set-inner-html'

//component imports
import CallToAction from '../components/service/callToAction'
import ServiceHeader from '../components/service/serviceHeader'
import OtherServices from '../components/service/otherServices'
import Blog from '../components/home/recentBlogs'

//Redux Imports
import {connect} from 'react-redux'
import {saveServiceData, saveCurrentServiceData} from '../../redux/actions/service/index'
import {setLoadingStatus} from '../../redux/actions/loading/loading'

const styles = {

}

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    saveServiceData: data => dispatch(saveServiceData(data)),
    saveCurrentServiceData: data => dispatch(saveCurrentServiceData(data)),
    setLoadingStatus: status => dispatch(setLoadingStatus(status))
})

class service extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            pageReady: false,
            serviceID: null,
        }
        
    }

    componentWillMount(){
       this.loadPage();
       this.props.history.listen(this.onRouteChange)
    }

    onRouteChange = (location, more) => {
        console.log('Route changed', location);
        
        //ensure route is in service path
        if(String(location.pathname).slice(0, 8) === '/service'){
            this.loadPage(location)
        }
    }
    
    loadPage = (nextProps) => {
        console.log('next props', nextProps)
        var slug;
        this.props.setLoadingStatus({loading: true})
        if(nextProps){
            slug = this.findServicePageName(nextProps.pathname)
        }
        else{
            slug = this.findServicePageName();
        }
            this.findPageDataBySlug(slug).then((data)=>{
                this.props.saveCurrentServiceData(data);
                this.setState({pageReady:true})
            })
            .catch(err=>{
                console.log('Unable to fetch content for specfied page', err);
                this.setState({pageReady:true})
                this.props.history.push('/404')
            })
    }


   

    

    findServicePageName = (nextPath) => {
        if(nextPath){
            console.log('Returning ', String(nextPath).slice(9))
            return String(nextPath).slice(9)
        }
        else{
            return this.props.match.params.slug;
        }
    }

    findPageDataBySlug = (slug) => {
        return(
            new Promise((resolve, reject)=>{
                var services = this.props.ServiceReducer.services;
                for(var i=0; i < services.length; i++){
                    if(services[i].slug === slug){
                         return resolve(services[i])
                    }
                    else{
                        if(i + 1 === services.length){
                            console.log(`i=${i}  + 1 = ${i+1} | Length is ${services.length} | Match => ${i + 1 === services.length}  `)
                            return reject(`Unable to find specified slug: '${slug}'`)
                        }
                    }
                }
                
            })
        )
    }

    render(){

        return(
            <div>
                {this.state.pageReady ? 
                    <div>  
                        <CallToAction/>
                        {/*<ServiceHeader/>*/}
                        {/* Breadcrumb END */}
                        <div className="main-content">

                    <div className="overview-block-ptb">
                
                    {/* Choose From  */}
                    <div className="iq-choose-info pt-0  position-relative">
                        <div className="container">
                        <div className="row flex-row-reverse ">
                            {/*
                            <div className="col-lg-6 align-self-center mb-5">
                            <div className="iq-accordion">
                                <div className="iq-ad-block ">
                                <div className="ad-details">
                                    <p>
                                    </p>
                                </div>
                                </div> 
                                
                            </div>
                            </div>
                            */}
                            <InnerHTML html={this.props.ServiceReducer.currentService.html}/>
                            
                        </div>
                        </div>
                        <div className="scrollme">
                        <img src="/images/service/3.png" className="img-fluid service-three animateme" data-when="enter" data-from="0.5" data-to={0} data-translatex={100} alt="Page Graphic" />
                        </div>
                    </div>
                </div>
                            
                           
                            <OtherServices/>
                            <Blog/>
                        </div>
                    </div>: <div/>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(service))