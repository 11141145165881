import {SAVE_BLOG_CONTENT, SAVE_CURRENT_BLOG} from '../../constants/blog/index'

const initialState = {
    posts: [],
    currentBlog: []
}

const blogReducer = (state = initialState, action) => {
    switch(action.type){
        case SAVE_BLOG_CONTENT:
            return {
                ...state,
                posts: action.data
            }
        case SAVE_CURRENT_BLOG:
            return {
                ...state,
                currentBlog: action.data
            }
        default:
            return state
    }
}

export default blogReducer