import React from 'react'
import {StyleRoot} from 'radium'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import _ from 'lodash'



const mapStateToProps = state => {
    return state
}

class portfolio extends React.Component{

    render(){
        
        return(
            <StyleRoot >
                
                 <section className="iq-project position-relative p-0">
              
                    <div className="scrollme">
                        <img src="images/others/13.png" className="img-fluid project-overlay-left animateme" data-when="enter" data-from="0.5" data-to={0} data-translatey={100} alt="Page Graphic" />
                    </div>
                    <div className="container" style={{paddingTop: '100px'}}>
                        <div className="row mb-3">
                        <div className="col-lg-12">
                            <h2 className="display-2  iq-fw-9 d-inline-block title"><span className="counter">{typeof(this.props.PortfolioReducer.portfolio) != 'undefined' ? this.props.PortfolioReducer.portfolio.length : ''}</span> <sup className=" d-inline-block">+</sup></h2>
                            <h3 className="d-inline-block  iq-fw-8">Project Done</h3>
                            <Link className="slide-button button mt-4 float-right explore-btn" to="/portfolio">
                            <div className="first">Explore All </div>
                            <div className="second">Explore All </div>
                            </Link>
                        </div>
                        </div>
                    </div>
                    
                    <div className="container-fluid" style={{zIndex: 1}}>
                        {typeof(this.props.PortfolioReducer.portfolio) != 'undefined' ?
                            <div className="row">
                                {this.props.PortfolioReducer.portfolio.map(p=>{
                                    return(
                                       
                                            <div className="iq-masonry-item creative col-md-3" key={`portfolioMasony-${p.id}`}>
                                                 
                                                    <div className="iq-portfolio">
                                                        <Link to={`/portfolio/${p.slug}`}>
                                                            <img 
                                                                src={_.get(p, 'images[0].imgSrc', '')/*p.images[0].imgSrc*/} 
                                                                className="img-fluid" 
                                                                alt={_.get(p, 'images[0].imgAlt')} 
                                                            />
                                                        </Link>
                                                            <div className="portfolio-info">
                                                            <Link to={`/portfolio/${p.slug}`}>
                                                                <span className="text-black">
                                                                    {p.name}
                                                                </span>
                                                            </Link>
                                                        <a href={p.url} className="text-uppercase text-gray float-right" target='_new'>
                                                            Website
                                                        </a>
                                                        </div>
                                                    </div>
                                                
                                            </div>
                                        
                                    )
                                })}
                            </div> : <div/>
                        }
                           
                    </div>
                    </section>
                    
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps)(portfolio)