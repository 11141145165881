import React from 'react'
import withStyles from 'react-jss'
import {StyleRoot} from 'radium'
import {connect} from 'react-redux'
import OurServices from '../components/service/otherServices'
import HeaderImage from '../../assets/images/aboutUs/header.jpg'
import LogoImage from '../../assets/images/template/logo.png'
import {saveTeamData} from '../../redux/actions/team/index'
import Axios from 'axios';
import Blog from '../components/home/recentBlogs'
import {Link} from 'react-router-dom'

const styles = {}

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    saveTeamData: teamData => dispatch(saveTeamData(teamData))
})


class aboutUs extends React.Component{
    componentWillMount(){
        Axios({
            method: 'get',
            url: 'https://api.clickficks.com/team'
        })
        .then(res=>{
            this.props.saveTeamData(res.data)
        })
        
    }
    
    render(){
        return(
            <StyleRoot>
                <div>
                    <section className="iq-breadcrumb">
                        <div className="iq-breadcrumb-info">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12 col-md-7 align-self-center">
                                <h2 className="iq-fw-8 mb-3">About Us</h2>
                                <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                </ol>
                                </nav>
                            </div>
                            <div className="col-md-5">
                                <img src={HeaderImage} className="img-fluid" alt='Header graphic' />
                            </div>
                            </div>          </div>
                        </div>
                        <div className="iq-breadcrumb-img">
                        <img src="images/breadcrumb/02.png" className="img-fluid breadcrumb-two" alt="Page Graphic" />
                        </div>
                    </section>
                    {/* Breadcrumb END */}
                    {/* Main Content */}
                    <div className="main-content">
                        {/* Works */}
                        <section className="iq-works position-relative">
                        <OurServices/>
                        <img src="images/service/2.png" className="img-fluid service-two" alt="Page Graphic" />
                        </section>
                        {/* Works END */}
                        {/* Choose From  */}
                        <section className="iq-solutions pt-0">
                        <div className="container">
                            <div className="row flex-row-reverse">
                            <div className="col-lg-6 align-self-center">
                                <h2 className="iq-fw-8 mb-2">Who We are</h2>
                                <p className="mb-3">
                                    We are a full stack javasript web development and marketing firm based in Houston, Tx. Existing marketing companies at the time were very fragmented
                                    from the website development process, which created business processes with many inefficiencies. We were created to patch the existing gap between website development and marketing for small business owners.
                                </p>
                                <p className="mb-3">
                                    We remove all the barriers of entry for small business owners looking to use more technology in their businesses.
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <img src={LogoImage} className="img-fluid" alt='Clickficks Logo' />
                            </div>
                            </div>
                        </div>
                        </section>
                        {/* Choose From END */}
                        {/* Team section */}
                        <section className="iq-aboutteam  main-bg position-relative">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12 text-center">
                                <div className="section-title">
                                <p className="mt-5 text-uppercase text-white iq-fw-3 iq-ls-3">Meet the team</p>
                                <h2 className="title  text-white iq-fw-8">Our Team</h2>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            {this.props.TeamReducer.team.map(t=>{
                                return(
                                    <div>
                                        {t.teamMember === 1 ?
                                            <div className="col-lg-3 col-md-6">
                                                <div className="team-box mb-4">
                                                <div className="team-img text-center">
                                                    <img src={t.img} className="img-fluid rounded-circle" alt="Tyler Clay Head Shot" />
                                                </div>
                                                <div className="team-detail">
                                                    <div className="team-plus"><i className="fas fa-plus" /></div>
                                                    <div className="team-info">
                                                    <h6 className="mb-0 text-white ">{t.fullName}</h6>
                                                    <span className="mb-0 text-white text-gray iq-fw-4">{t.title}</span>
                                                    </div>
                                                </div>
                                                <div className="team-hover">
                                                    <p>{String(t.desc).slice(0, 100) + '...'}</p>
                                                    <ul className="list-inline">
                                                    <li className="list-inline-item"><a href={t.facebook} target='_new'><i className="fab fa-facebook-f" /></a></li>
                                                    <li className="list-inline-item"><a href={t.twitter} target='_new'><i className="fab fa-twitter" /></a></li>
                                                    <li className="list-inline-item"><a href={t.linkedIn} target='_new'><i className="fab fa-linkedin-in" /></a></li>
                                                    <li className="list-inline-item"><a href={t.instagram} target='_new'><i className="fab fa-instagram" /></a></li>
                                                    </ul>
                                                </div>
                                                </div>
                                            </div> : <div/>
                                        }
                                    </div>
                                )
                            })}
                            
                            {/*
                            <div className="col-lg-12 text-center">
                                <a className="slide-button button mb-0 mt-5" href="team.html">
                                <div className="first">Explore All Team</div>
                                <div className="second">Explore All Team</div>
                                </a>
                            </div>*/}
                            </div>
                            
                        </div>
                        </section>
                        {/* Team END */}
                        {/* Our History */}
                        {/*
                        <section className="iq-history">
                        <div className="container">
                            <div className="col-sm-12 text-center">
                            <div className="section-title">
                                <p className="mb-0 text-uppercase iq-fw-5 iq-ls-3">Our History</p>
                                <h2 className="title iq-fw-8">Company History</h2>
                            </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12">
                                <div className="cd-horizontal-timeline">
                                <div className="timeline">
                                    <div className="events-wrapper">
                                    <div className="events">
                                        <ol>
                                        <li><a href="#" data-date="12/01/2019" className="selected">16 Jan</a></li>
                                        </ol>
                                        <span className="filling-line" aria-hidden="true" />
                                    </div>
                                    </div>
                                    <ul className="cd-timeline-navigation">
                                    <li><a href="#" className="prev inactive">Prev</a></li>
                                    <li><a href="#" className="next">Next</a></li>
                                    </ul>
                                </div>
                                <div className="events-content">
                                    <ol>
                                    <li className=" selected " data-date="12/01/2019">
                                        <h3 className="text-white iq-fw-8 mb-3">Clickficks Takes New Direction</h3>
                                        <p className="text-white mb-0">On this day the owner of Clickficks decided to pursue the mission of bringing technology and marketing together for small business owners</p>
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>
                        */}
                        {/* Our History END */}
                        {/* Our Blog */}
                       <Blog/>
                        {/* Our Blog END */}
  </div>


</div>

            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(aboutUs))