import React from 'react'
import {StyleRoot} from 'radium'
import OurSuccessImage from '../../../assets/images/ourSuccess/ourSuccess.svg'
import {Link} from 'react-router-dom'



class ourSuccess extends React.Component{
    
    render(){
        return(
            <StyleRoot>
                <section className="iq-choose-info position-relative">
                    <div className="container">
                       
                            <div className="row flex-row-reverse align-items-center h-100">
                            <div className="col-lg-6 align-self-center">
                                <p className="text-uppercase  iq-fw-3 iq-ls-3">WHY CLICKFICKS?</p>
                                <h2 className="iq-fw-8 mb-2">WHAT MAKES US DIFFERENT</h2>
                                <p>
                               We got tired of seeing businesses settle for sub-par websites and decided it was time for a company to provide development seamlessly. We create custom business websites with automated marketing tools integrated. We aim to deliver interactive dashboards and business tools.
                                </p>
                                <div className="mt-3 d-inline-block">
                                <Link className="slide-button button float-left" to='/about-us'>
                                    <div className="first">Explore More </div>
                                    <div className="second">About Us</div>
                                </Link>
                                {/*         Play Button     */}
                                {/* <a href="https://www.youtube.com/watch?v=0O2aH4XLbto" className="video-play popup-youtube float-left ml-3"><i className="fas fa-play" /></a>*/}
                                </div>
                            </div>
                            <div className="col-lg-6 iq-rmt-40">
                                <img src={OurSuccessImage} className="img-fluid" alt="Success Graphic" style={{maxHeight: '400px'}} />
                            </div>
                            </div>
                      
                    </div>
                </section>
            </StyleRoot>
        )
    }
}

export default ourSuccess