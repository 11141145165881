import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {StyleRoot} from 'radium'
import {Row, Col,  Container} from 'reactstrap'
import SimpleReactValidator from 'simple-react-validator'
import {saveFormServices, saveService, saveFormServiceStatus, removeService, nextFormStep} from '../../../redux/actions/quote/index'
import {setLoadingStatus} from '../../../redux/actions/loading/loading'
import Axios from 'axios'
import * as Scroll from 'react-scroll';



const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    saveFormServices: service => dispatch(saveFormServices(service)),
    saveService: service => dispatch(saveService(service)),
    removeService: service => dispatch(removeService(service)),
    setLoadingStatus: status => dispatch(setLoadingStatus(status)),
    saveFormServiceStatus: status => dispatch(saveFormServiceStatus(status)),
    nextFormStep: () => dispatch(nextFormStep())
})

const styles  = {
    serviceCol:{
        width: '100%',
        height: '200px',
        cursor: 'pointer',
        '@media (max-width: 600px)': {
            height: '150px'
        }
    },
    serviceBlock:{
        border: '3px solid #7C5FD5',
        boxShadow: '5px 5px 30.px #33E2A0',
        width: '100%',
        height: '90%',
        marginTop: '5%',
        borderRadius: '5px',
        position: 'relative'
    },
    bottomBlockBar: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        width: '100%',
        textAlign: 'center',
        backgroundColor: '#33e2a0',
        color: 'white',
        fontWeight: '400'
    },
    bottomBlockBarActive:{
        backgroundColor: '#7c5fd5 !important',
    },
    serviceBlockTitle: {
        borderBottom: '2px solid #33e2a0',
        textAlign: 'center',
        color: 'black',
        fontWeight: '600',
        '@media (max-width: 600px)':{
            fontSize: '0.8em',
            fontWeight: '0'
        }
    },
    icon: {
        marginLeft: '35%',
        marginRight: '35%',
        width: '30%',
        marginTop: '5px',
    },
    icons:{

    },
    header: {
        textAlign: 'center',
        marginTop: '-58px',
        '@media (max-width: 600px)':{
            fontSize: '1em',
            marginTop: '-65px'
        }
    },
    submitButton: {
        width: '20%',
        
        margin: '0 40% 0 40% !important',
        '@media (max-width: 600px)':{
            width: '60%',
            margin: '0 20% 0 20% !important',
            position: 'fixed',
        }
    }
}

class service extends React.Component{


    constructor(props){
        super(props);
        this.state = {
            width: 0
        }
        this.validator = new SimpleReactValidator();
        this.updateDimensions()
    }

    componentDidMount(){

        this.props.setLoadingStatus({loading:true});
        Scroll.animateScroll.scrollToTop()
        Axios({
            method: 'get',
            url: 'https://api.clickficks.com/service/get/FormServices'
        })
        .then(res=>{
            this.props.saveFormServices(res.data)
        })
        .catch(err=>{
            console.log(err);
            this.props.setLoadingStatus({loading:false})
        })
        .then(()=>{
            this.props.saveFormServiceStatus(true);
            this.props.setLoadingStatus({loading: false})
        })
        .catch(err=>{
            this.props.setLoadingStatus({loading: false})
        })

        window.addEventListener('resize', this.updateDimensions.bind(this))
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions.bind(this))
    }

    updateDimensions = () => {
        this.setState({
            width: window.innerWidth
        })
    }
    

    handleTileClick = (event) => {
        
        if(this.itemSelected(event.currentTarget.id)){
            this.props.removeService(event.currentTarget.id)
        }
        else{
            this.props.saveService(event.currentTarget.id)
        }
    }

    itemSelected = (id) => {
        for(var i = 0;  i < this.props.QuoteReducer.services.length; i++){
            if(id === this.props.QuoteReducer.services[i]){
                return true 
            }
        }
        return false;
        
    }

    onSubmit = () => {
        this.props.nextFormStep();
    }

    

    render(){
        const {classes} = this.props;
        const AnimationStyles = require('../../../utils/animations')
        return(
            <StyleRoot>
                <Container>
                        {this.props.QuoteReducer.formServiceStatus ?
                            <section>
                                <Row>
                                    <Col md={12}>
                                        <h4 className={classes.header} style={AnimationStyles.styles.zoom_in_down}>Select the services your are interested in and hit continue</h4>
                                    </Col>
                                    {this.props.QuoteReducer.formServices.map(s=>{
                                        return(
                                            <Col md={3} xs={6} className={classes.serviceCol} id={s.value} onClick={this.handleTileClick}>
                                                <div className={classes.serviceBlock}>
                                                    <div className={classes.serviceBlockTitle} style={AnimationStyles.styles.fade_in_up}>
                                                        {this.state.width < 350 ? s.mobileName ? s.mobileName : s.name : s.name}
                                                    </div>
                                                    <div className={classes.icons}>
                                                        <img src={s.icon} alt={this.props.name} className={classes.icon}/>
                                                    </div>

                                                    <div className={`${classes.bottomBlockBar} ${this.itemSelected(s.value) ? classes.bottomBlockBarActive : '' }`}>
                                                        {this.itemSelected(s.value) ? 'SELECTED' : 'SELECT' }
                                                        {console.log(this.itemSelected(s.value))}
                                                    </div>
                                                </div>
                                               
                                            </Col>
                                        )
                                    })}           
                                </Row>
                                <Row>
                                    <Col xs={12} style={{marginTop: '60px'}}>
                                        <div className={`slide-button button bt-subscribe subscription-button ${classes.submitButton}`} onClick={this.onSubmit}>
                                            <div className="first">Continue</div>
                                            <div className="second">We can do that!</div>
                                        </div>
                                    </Col>
                                </Row>
                                
                                    
                            
                            </section> : <div/>
                        }
                        </Container>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(service))