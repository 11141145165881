import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {StyleRoot} from 'radium'
import Axios from 'axios'
import Name from '../components/quote/name'
import Email from '../components/quote/email'
import Phone from  '../components/quote/phone'
import Website from '../components/quote/website'
import Services from '../components/quote/services'
import Details from '../components/quote/details'
import {nextFormStep} from '../../redux/actions/quote/index'
import {Alert} from 'reactstrap'
import SimpleReactValidator from 'simple-react-validator'

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    nextFormStep: () => dispatch(nextFormStep())
})
const styles = {
    labels: {

    },
    slideBtn: {
       width: '30%',
       margin: '0 35% 0 35%',
       '@media (max-width: 600px)':{
            width: '60%',
            margin: '0 20% 0 20% !important'
        }
    }
}

class quote extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            alert: {
                status: null,
                color: null,
                message: null
            }
        }
        this.validator = new SimpleReactValidator()
    }
    componentDidMount(){
        
    }
    
    handleSubmit = () => {
        if(this.validator.allValid()){
            this.postQuoteRequest();
        }
        else{
            this.validator.showMessages();
            this.forceUpdate()
        }
    }
    resetAlert = () => {
        var alert = {
            status: null,
            color: null,
            message: null
        }
        this.setState({
            alert: alert
        })
    }


    postQuoteRequest = () => {
        
        return(
                Axios({
                    method: 'post',
                    url: 'https://api.clickficks.com/quote/postQuoteRequest',
                    data: {
                        name: this.props.QuoteReducer.name,
                        email: this.props.QuoteReducer.email,
                        phone: this.props.QuoteReducer.phone,
                        website: this.props.QuoteReducer.website,
                        services: this.props.QuoteReducer.services.toString(),
                        details: this.props.QuoteReducer.details
                    }
                })
                .then(res=>{
                    this.resetAlert()
                    this.props.history.push('/thank-you')
                })
                .catch(err=> {
                    var alert = Object.assign({}, this.state.alert);
                    alert.color = 'red';
                    alert.status = true;
                    alert.message = "A network error occurred and we were unable to perform your request. We are more than happy to serve you. Please give us a call!";
                    this.setState({
                        alert: alert
                    })
                })
        )
    }


    
    
    render(){
        const {classes} = this.props;
        const AnimationStyles = require('../../utils/animations');


        return(
            <StyleRoot>
                    <section className="iq-breadcrumb">
                        <div className="iq-breadcrumb-info">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-7 align-self-center">
                                    <h2 className="iq-fw-9 mb-3">
                                        {this.props.QuoteReducer.quoteStep === 4 ? 'Last Step!' : 'Quote'}
                                    </h2>
                                </div>
                            </div> 
                               
                            </div>
                        </div>
                        
                    </section>
                    <div className="main-content">
                        {typeof(this.state.alert.status) != 'undefined' ?
                            <Alert color={this.state.alert.color} style={AnimationStyles.styles.zoom_in_down}>
                                {this.state.alert.message}
                            </Alert> : <div/>
                        }
                        {this.props.QuoteReducer.quoteStep === 1 ?
                            <div className={classes.formGroupThree}>
                                <Services/>
                            </div> : <div/>
                        }
                        {this.props.QuoteReducer.quoteStep === 2 ?
                            <div className={classes.formGroupTwo}>
                                <Website/>
                            </div> : <div/>
                        }
                        {this.props.QuoteReducer.quoteStep === 3 ?
                            <div className={classes.formGroupFour}>
                                <Details/>
                            </div> : <div/>
                        }
                        {this.props.QuoteReducer.quoteStep === 4 ?
                            <div className={classes.formGroupOne}>
                                
                                <Name/>
                                <Email/>
                                <Phone/>
                                <div className={`slide-button button ${classes.slideBtn}`} onClick={this.handleSubmit}>
                                    <div className="first">Submit</div>
                                    <div className="second">Help on the way!</div>
                                </div>
                                <div style={{textAlign:'center'}}>
                                    {this.validator.message('Name', this.props.QuoteReducer.name, 'required')}
                                    {this.validator.message('Email', this.props.QuoteReducer.email, 'required|email')}
                                    {this.validator.message('Phone', this.props.QuoteReducer.phone, 'required|phone')}
                                </div>
                            </div> : <div/>
                        }
                        
                        
                        
                    </div>
                    
                   
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(quote))