import React from 'react'
import withStyles from 'react-jss'
import {StyleRoot} from 'radium'
import {connect} from 'react-redux'
import Logo from '../../assets/images/template/logo.png'
import {saveBlogContents} from '../../redux/actions/blog/index'
import {Link} from 'react-router-dom'
import _ from 'lodash'

const styles = {}

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    saveBlogContents: data => dispatch(saveBlogContents(data))
})

class blog extends React.Component{
  
    
    strip(html){
        var doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
     }

    render(){
     

        return(
            <StyleRoot>
                <div>
                    <section className="iq-breadcrumb">
                        <div className="iq-breadcrumb-info">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12 col-md-7 align-self-center">
                                <h2 className="iq-fw-8 mb-3">Blog</h2>
                                <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">All Blogs</li>
                                </ol>
                                </nav>
                            </div>
                            <div className="col-md-5">
                                <img src={Logo} className="img-fluid" alt="Clickficks Logo" />
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="iq-breadcrumb-img">
                        
                        </div>
                    </section>
                    {/* Breadcrumb END */}
                    {/* Main Content */}
                    <div className="main-content">
                        {/* Blogs */}
                        <section className="iq-blogs">
                        <div className="container">
                            <div className="row">

                            
                           
                            {this.props.BlogReducer.posts.map(p=>{
                                return(
                                    
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <Link to={`/blog/${p.slug}`}>
                                            <div className="main-blog riq-mt-40">
                                            <div className="blog-img">
                                               
                                                {p.images ?
                                                    <img src={_.get(p, 'images[0].url')} className='img-fluid' alt={_.get(p, 'images[0].alt')} style={{maxHeight: '250px'}}/> : <></>
                                                }
                                            </div>
                                            <div className="blog-detail">
                                                <span className="main-color iq-fw-8" href="javascipt:void(0)">{p.category}</span>
                                                <Link to={`/blog/${p.slug}`}>
                                                    <h5 className="mt-1 iq-fw-8">{p.title}</h5>
                                                </Link>
                                                <p className="mb-0">{ this.strip(p.html).slice(0,75) + '...' }</p>
                                                <div className="blog-info">
                                                <img src={p.authorImg} className="img-fluid rounded-circle mr-3 user-img" alt={`Photo of ${p.authorName}`} /><span className="iq-fw-8 font-c iq-font-18">{p.authorName}</span>
                                                {/*
                                                    <ul className="d-inline-block float-right">
                                                        <li className="d-inline-block"><a href="javascript:void(0)"><i className="far fa-thumbs-up" /> <span className="iq-fw-8 iq-font-14">90</span></a></li>
                                                        <li className="d-inline-block"><a href="javascript:void(0)"><i className="far fa-comment-alt" /> <span className="iq-fw-8 iq-font-14">120</span></a></li>
                                                    </ul>
                                                */}
                                                </div>
                                            </div>
                                            </div>
                                            </Link>
                                        </div>
                                  
                                )
                            })}
                            

                            </div>
                            <div className="row mt-5">
                            <div className="col-sm-12">
                                <nav>
                                <ul className="pagination justify-content-center">
                                    <li className="page-item">
                                    <span className="page-link"><i className="fas fa-arrow-left" /></span>
                                    </li>
                                   
                                    <li className="page-item active" aria-current="page">
                                        <span className="page-link">
                                            1
                                            <span className="sr-only">(current)</span>
                                        </span>
                                        </li>
                                    <li className="page-item">
                                    <a className="page-link" href="#"><i className="fas fa-arrow-right" /></a>
                                    </li>
                                </ul>
                                </nav>
                            </div>
                            </div>
                        </div>
                        </section>
                    </div>
                </div>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(blog))