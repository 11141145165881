import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {StyleRoot} from 'radium'
import Logo from '../../assets/images/template/logo.png'
import Axios from 'axios'

//material ui imports
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
    questionHeader: {
        color: '#7C60D5',
        fontWeight: '600 !important',
        fontSize: '1.2em !important',
        padding: '10px'
    },
    answerContainer: {
        background: '#EEEEEE',
        padding: '5px',
    }
}

const mapStateToProps = state => {
    return state
}

class faq extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data: null
        }
    }
    componentDidMount(){
        Axios({
            method: 'get',
            url: 'https://api.clickficks.com/faq'
        })
        .then(res=>{
            this.setState({
                data: res.data
            })
        })
        .catch(err=>{
            console.log(err);
            alert('For some reason we can\'t load data for this page :( Please try again later!')
        })
    }
    componentWillUnmount(){

    }
    render(){
        const {classes} = this.props;
        const AnimationStyles = require('../../utils/animations');

        return(
            <StyleRoot>
                <div>
                    <section className="iq-breadcrumb">
                        <div className="iq-breadcrumb-info">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12 col-md-7 align-self-center">
                                <h2 className="iq-fw-9 mb-3">FAQ</h2>
                                <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">FAQ</li>
                                </ol>
                                </nav>
                            </div>
                            <div className="col-md-5">
                                <img src={Logo} className="img-fluid" alt="image" />
                            </div>
                            </div>          
                        </div>
                        </div>
                        <div className="iq-breadcrumb-img">
                            <img src="images/breadcrumb/02.png" className="img-fluid breadcrumb-two" alt="image" />
                        </div>
                    </section>
                    {/* Breadcrumb END */}
                    {/* Main Content */}
                    <div className="main-content">
                        {/* Faq */}
                        <section className="iq-asked position-relative">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12 text-center">
                                <div className="section-title">
                                <p className="mt-2 text-uppercase  iq-fw-3 iq-ls-3">Faq</p>
                                <h2 className="title iq-fw-8">Frequently Asked Questions</h2>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-12">
                                {typeof(this.state.data) != 'undefined' && this.state.data != null ?
                                    <div>
                                        {this.state.data.map(data => {
                                            return(
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls='panel1a-content'
                                                        id='panel1a-header'
                                                    >
                                                        <Typography className={classes.questionHeader}>
                                                            {data.question}
                                                        </Typography>   
                                                    </ExpansionPanelSummary>    
                                                    <ExpansionPanelDetails className={classes.answerContainer}>
                                                        <Typography>
                                                            {data.answer}
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel> 
                                            )
                                        })}
                                    </div> : <div/>}
                            </div>
                            </div>
                        </div>
                        </section>
                        {/* Faq END */}
                    </div>
                </div>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps)(withStyles(styles)(faq))