import React from 'react'
import {StyleRoot} from 'radium'

//Compomnent Imports
import SplitCallToAction from '../components/home/splitCallToAction'
import NewCallToAction from '../components/home/newCallToAction'
import HowWeWork from '../components/home/howWeWork'
import OurSuccess from '../components/home/ourSuccess'
import OurAchievements from '../components/home/ourAchievements'
import Portfolio from '../components/common/portfolio'
import Features from '../components/home/features'
import OurTeam from '../components/home/ourTeam'
import ClientFeedback from '../components/home/clientFeedback'
import RecentBlogs from '../components/home/recentBlogs'

//Redux imports
import {connect} from 'react-redux'
import {setLoadingStatus} from '../../redux/actions/loading/loading'

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    setLoadingStatus: status => dispatch(setLoadingStatus(status))
})
class home extends React.Component{
    render(){
        return(
           <StyleRoot>
               <NewCallToAction/>
               {/*<CallToAction/>*/}
               <div> 
                  
                    <div className="main-content">
                        <HowWeWork/>
                        <OurSuccess/>
                        <OurAchievements/>
                        <Portfolio/>
                        <Features/>
                        <OurTeam/>
                        <ClientFeedback/>
                        <RecentBlogs/>
                    </div>
                    <div className="clearfix" />
                </div>
           </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(home)