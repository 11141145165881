import {SAVE_SERVICE_DATA, SAVE_CURRENT_SERVICE_DATA} from '../../constants/service/index'

const initialState = [];

const loadingReducer = (state = initialState, action) => {
    switch(action.type){
        case SAVE_SERVICE_DATA:
            return{ ...state,
                services: action.data
            }
        case SAVE_CURRENT_SERVICE_DATA:
            return{ ...state,
                currentService: action.data
            }
        default:
            return state
    }
}

export default loadingReducer