import React from 'react'
import Logo from '../../../assets/images/template/logo.png'
import LocationImage from '../../../assets/images/footer/location.jpg'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

const mapStateToProps = state => {
    return state
}

class footer extends React.Component{
    render(){
        return(
            <footer className="footer-three position-relative">
                <div className="container">
                <div className="row ">
                    <div className=" col-lg-5 col-md-6  iq-rmt-40">
                    <div className="iq-footer-logo mb-5">
                        <Link to="/"><img src={Logo} className="img-fluid" alt="Logo Graphic" /></Link>
                    </div>
                    <div className="position-relative subscribe-form">
                        <div className="form-group mb-0">
                        <input type="email" className="form-control position-relative subscription-email  mb-0" placeholder="Enter Email" />
                        </div>
                        <Link className="slide-button button bt-subscribe subscription-button" to='/quote'>
                            <div className="first">Subscribe</div>
                            <div className="second">Thank You</div>
                        </Link>
                    </div>
                    <div className="contact mt-4 d-inline-block">
                        <i className="fa fa-phone" /><a href='tel:7132692011' className="iq-fw-5 ml-2"> +1 713 269 2011</a>
                        <div className="email d-inline-block"><a href="mailto:tyler@clickficks.com">tyler@clickficks.com</a></div>
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-6 footer-link  iq-rmt-40">
                    <h5 className="iq-fw-7 ">Links</h5>
                    <ul className="list-inline">
                        <li className="list-item"><Link to='/'>Home</Link></li>
                        <li className="list-item"><Link to='/about-us'>About us</Link></li>
                        <li className="list-item"><Link to='/faq'>FAQ</Link></li>
                        <li className="list-item"><Link to='/blog'>Blog</Link></li>
                        <li className="list-item"><Link to='/portfolio'>Portfolio</Link></li>
                        <li className="list-item"><Link to='/contact-us'>Contact Us</Link></li>
                        <li className="list-item"><Link to='/privacy-policy'>Privacy Policy</Link></li>
                    </ul>
                    </div>
                    <div className="col-lg-2 col-md-6 footer-link iq-rmt-40">
                    <h5 className="iq-fw-7 ">Services</h5>
                    {typeof(this.props.ServiceReducer.services) != 'undefined' ?
                        <ul className="list-inline">
                            
                            {this.props.ServiceReducer.services.map(s=>{
                                return(
                                    <li className="list-item" key={`serviceFooterLink-${s.id}`}>
                                        <Link to={`/service/${s.slug}`}>{s.title}</Link>
                                    </li>
                                )
                            })
                            }
                        </ul> : <div/>
                    }
                    </div>
                    <div className="col-lg-3 col-md-6 footer-link iq-rmt-40">
                    <div className="iq-location">
                        <h5 className="iq-fw-7 ">Location</h5>
                        <span>He proudly serve <b>Houston, Tx</b> and the surrounding area!</span>
                        <Link to='/contact-us'><img src={LocationImage} className="img-fluid mt-5 " alt="Page Graphic" /></Link>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                    <div className="social-media">
                        <ul className="social">
                        <li><a href="https://www.facebook.com/clickficks/"><i className="fab fa-facebook-f iq-fw-6" /></a></li>
                        <li><a href="https://twitter.com/clickficks_"><i className="fab fa-twitter iq-fw-6" /></a></li>
                        <li><a href="https://www.linkedin.com/in/tyler-c-435455b8/"><i className="fab fa-linkedin iq-fw-6" /></a></li>
                        </ul>
                    </div>
                    
                    </div>
                    <div className="col-lg-6 col-md-6 text-right">
                    <div className="footer-copyright iq-fw-5">Copyrights 2019 © <Link to='/' className="text-green iq-fw-5">Clickficks</Link> </div>
                    </div>
                </div>
                </div>
                {/* back-to-top */}
                <div id="back-to-top">
                <a className="top" id="top" href="#top"><i className="ion-ios-arrow-thin-up" /></a>
                </div>
            {/* back-to-top End */}
        </footer>
        )
    }
}

export default connect(mapStateToProps)(footer)