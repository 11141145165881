import React, {useEffect, useState, useRef} from 'react'
import {StyleRoot} from 'radium'
import LogoImage from '../../../assets/images/template/logo.png'
import {Link, withRouter} from 'react-router-dom'
import {useSpring, animated, config, useChain} from 'react-spring'
import {FaTimes} from 'react-icons/fa'
import { IconContext } from "react-icons";
import {connect} from 'react-redux'
import withStyles from 'react-jss'
import {Row, Col, NavbarBrand} from 'reactstrap'
import {
    Navbar as ReactStrapNavbar
 } from 'reactstrap';


const mapStateToProps = state => {
    return state
}


const styles = {
    menuBtnUl: {
        position: 'absolute',
        right: '40px',
        top: '25px',
        cursor: 'pointer',
        '@media (max-width: 600px)':{
            right: '5px'
        }
    },
    menuIcon: {
        color: 'black',
        fontSize: '1.5em',
        transform: 'rotate(45deg)',
        display: 'inline-block'
    },
    menuIconText: {
        color: 'black',
        fontWeight: '600',
        textAlign: 'center',
        display: 'inline-block'
    },
    LogoImage: {
        position: 'fixed',
        top: '0px',
        left: '0px',
        '@media (max-width: 600px)':{
            left: '-50px',
            top: '10px'
        }
        
    },
    phoneLink:{
        position: 'relative',
        left: '40%',
        '@media (max-width: 600px)':{
            display: 'none'
        }
    },
    navbar: {
       position: 'fixed',
       top: '0px',
       left: '0px',
       width: '100%',
       height: '76px'
    },
    fullScreenMenu: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: '0vh 0% 0vh 0%',
        height: '100vh',
        borderRadius: '5px',
        padding: '25px',
        zIndex: '100',
        paddingTop: '150px',
        width: '100%',
        '@media (max-width: 600px)':{
            paddingTop: '80px',
        }
    },
    navLinkRoot: { 
        fontSize: '2.5em',
        color: 'white',
        '&:hover':{
            color: 'white'
        },
        fontWeight: '600',
        '@media (max-width: 600px)':{
            fontSize: '1.2em'
        }
    },
    navLinkService:{
        fontSize: '1.8em',
        color: 'white',
        '&:hover':{
            color: '#7C60D5',
            fontWeight: '500'
        },
        fontWeight: '500',
        '@media (max-width: 600px)':{
            fontSize: '1em',
            padding: '0px'
        }
    },
    serviceLinkLi:{
        margin: '15px 0 15px 0',
        '@media (max-width: 420px)':{
            margin: '-10px'
        }
    },
    navLinks: {
        '@media (max-width: 600px)':{
            //marginTop: '200px'
        }
    },
    navLink: {
        fontSize: '2.5em',
        color: 'white',
        '&:hover':{
            color: '#7C60D5',
            fontWeight: '500',
        },
        '@media (max-width: 768px)':{
            fontSize: '1.2em',
    
        }
    },
    navLinkLi:{
        marginTop: '45px',
        '@media (max-width: 600px)':{
            marginTop: '0px',
        }
    },
    
}
    

    const Navbar = (props) =>{
        const {classes} = props;
        const [isToggled, setToggle] = useState(false)

        const springRef = useRef()
        const toggle = useSpring({
            config: { mass: 2, tension: 200, friction: 50 },
            opacity: isToggled ? .95 : 0,
            height: isToggled ? '100%': '0%',
            width: isToggled ? '100%': '0%',
            background: isToggled ? '#33E09F' : '#7C60D5',
            pointerEvents: isToggled ?  'auto' :'none',
            ref: springRef
        });
        const linkAnimationRef = useRef();

        const linkAnimation = useSpring({
            config: {mass: 1, tension: 70, friction: 50},
            fontSize: isToggled ? 29 : 20,
            fontWeight: isToggled ? 600 : 0,
            color: isToggled ? '#622AD2' : 'white',
            opacity: isToggled ? 1 : 0,
            
            ref: linkAnimationRef,
        })

        const serviceLinkAnimationRef = useRef();

        const serviceLinkAnimation = useSpring({
            config: { mass: 1, tension: 100, friction: 150 },
            paddingLeft: isToggled ? '50px' : '0px',
            opacity: isToggled ? 1 : 0,
            ref: serviceLinkAnimationRef
        })

        const serviceHeaderLinkAnimationRef = useRef()
        const serviceHeaderLinkAnimation = useSpring({
            config: config.wobbly,
            opacity: isToggled ? 1 : 0,
            ref: serviceHeaderLinkAnimationRef
        })

        const menuButtonAnimationRef = useRef();
        const menuButtonAnimation = useSpring({
            config: config.stiff,
            transform: isToggled ? 'rotate(45deg)' :'rotate(-360deg)'
        })

        useChain([springRef, linkAnimationRef, serviceLinkAnimationRef, serviceHeaderLinkAnimationRef,  menuButtonAnimationRef], [0,0.5])


        useEffect(()=> {
            document.body.style.overflow =  isToggled ? 'hidden' : 'scroll';
            
        })

        const toggleMenu = () =>{
            setToggle(!isToggled)
        }

        return(
            <StyleRoot>
                <header>
                    <ReactStrapNavbar color='white' expand='md' className={classes.navbar}>
                            <Link to='/' onClick={isToggled ? toggleMenu : null}>
                                    <img src={LogoImage} alt="logo"  className={`img-fluid pr-4 ${classes.LogoImage}`}/>
                            </Link> 
                        
                            <a href="tel:7132692011" className={classes.phoneLink}><i className={`fas fa-phone pr-2 pl-4`} /> +1 713 269 2011</a>
                            <ul className={classes.menuBtnUl} onClick={toggleMenu}>
                                <li> 
                                    <IconContext.Provider value={{className: classes.menuIcon}}>
                                        <animated.div style={menuButtonAnimation}>
                                            <span><FaTimes/></span> <span className={classes.menuIconText}>{isToggled ? 'CLOSE':'MENU'}</span>
                                        </animated.div>
                                       
                                    </IconContext.Provider>
                                </li>
                            </ul>
                    </ReactStrapNavbar>
                </header>
            
                    <div>
                        
                        <animated.div style={toggle} className={classes.fullScreenMenu} id='fullScreenMenu'>
                        {isToggled ?
                            <Row>
                                <Col md={6}>
                                    <animated.div className={classes.navLinkRoot} style={serviceHeaderLinkAnimation} >Services</animated.div>
                                    {typeof(props.ServiceReducer.services) != 'undefined' ?
                                        <animated.ul className="drop-down-multilevel" style={serviceLinkAnimation}>
                                            {props.ServiceReducer.services.map(s=>{
                                                return(
                                                    <li className={classes.serviceLinkLi} key={`serviceLink-${s.id}`}>
                                                        <Link to={`/service/${s.slug}`} onClick={toggleMenu} className={classes.navLinkService}>{s.title}<i className="fas"/></Link>
                                                    </li>
                                                )
                                            })}
                                        </animated.ul> : <ul/>
                                    }
                                </Col>
                                <Col md={6}>
                                    <animated.ul className={`menu-links ${classes.navLinks}`} style={linkAnimation}>
                                        <li className={classes.navLinkLi}>
                                            <Link className={classes.navLink} onClick={toggleMenu} to='/quote'>Quote Now</Link>
                                        </li>
                                        <li className={classes.navLinkLi}>
                                            <Link className={classes.navLink} onClick={toggleMenu} to='/portfolio'>Portfolio</Link>
                                        </li>
                                        <li className={classes.navLinkLi}>
                                            <Link className={classes.navLink} onClick={toggleMenu} to='/blog'>Blog</Link> 
                                        </li>
                                        <li className={classes.navLinkLi}>
                                            <Link className={classes.navLink} onClick={toggleMenu} to='/about-us'>About Us</Link> 
                                        </li>
                                        <li className={classes.navLinkLi}>
                                            <Link className={classes.navLink} onClick={toggleMenu} to='/contact-us'>Contact us</Link>
                                        </li>
                                    </animated.ul>
                                </Col>
                            </Row>
                        :
                        <div/>
                                }
                        </animated.div>
                    </div>
            </StyleRoot>
        )
    }

export default (withRouter(withStyles(styles)(connect(mapStateToProps)(Navbar))))
              