import React from 'react'
import withStyles from 'react-jss'
import {connect} from 'react-redux'
import {StyleRoot} from 'radium'
import {Row, Col, FormGroup, Input, Container} from 'reactstrap'
import SimpleReactValidator from 'simple-react-validator'
import {saveDetails, prevFormStep, nextFormStep} from '../../../redux/actions/quote/index'
import BackButton from './backButton'
import {withRouter} from 'react-router-dom'
import * as Scroll from 'react-scroll';

const styles  = {
    submitButton: {
        width: '20%',
        margin: '0 40% 0 40% !important',
        '@media (max-width: 800px)':{
            width: '60%',
            margin: '0 20% 0 20% !important'
        }
    },
    header: {
        textAlign: 'center',
        marginTop: '-58px',
        '@media (max-width: 600px)':{
            fontSize: '1em',
            marginTop: '-65px'
        }
    },
}

const mapStateToProps = state => {
    return state
}

const mapDispatchToProps = dispatch => ({
    saveDetails: details => dispatch(saveDetails(details)),
    prevFormStep: () => dispatch(prevFormStep()),
    nextFormStep: () => dispatch(nextFormStep())
})

class details extends React.Component{


    constructor(props){
        super(props);
        
        this.validator = new SimpleReactValidator()
    }

    componentDidMount(){
        Scroll.animateScroll.scrollToTop()
    }

    handleChange = (event) => {
        this.props.saveDetails(event.currentTarget.value)
    }

    handleSubmit = () => {
        this.props.nextFormStep()
    }

    goBack = () => {

    }

    render(){
        const {classes} = this.props;
        const AnimationStyles = require('../../../utils/animations')
        return(
            <StyleRoot>
                <Container>
                    <section>
                        <Row>
                            <Col xs={12} md={12}>
                               
                                    <FormGroup className={classes.nameInputFormGroup}>
                                        <h4 className={classes.header} style={AnimationStyles.styles.slide_in_right_slow}>Please enter any details on your needs. Hit continue if you have no details!</h4>
                                        <form className="position-relative subscribe-form">
                                            <div className="form-group mb-0">
                                                <Input type="textarea" className="form-control position-relative subscription-email  mb-0" id='details' value={this.props.QuoteReducer.details} onChange={this.handleChange} placeholder='Optional' />
                                            </div>
                                            
                                        </form>
                                        {this.validator.message('Details', this.props.QuoteReducer.details, 'required')}
                                    </FormGroup> 
                            </Col>
                            <Col xs={2}>
                                <BackButton/>
                            </Col>
                            <Col xs={10} style={{marginTop: '60px'}}>
                                <a className={`slide-button button bt-subscribe subscription-button ${classes.submitButton}`} onClick={this.handleSubmit}>
                                    <div className="first" onClick={this.onSubmit}>Submit</div>
                                    <div className="second">Last Step!</div>
                                </a>
                            </Col>
                        </Row> 
                    </section>
                </Container>
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(details)))