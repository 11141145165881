import {TOGGLE_NAVBAR} from '../../constants/navbar/index'

var initalState = {
    navbarOpen: false
}

const navbarReducer = (state=initalState, action) => {
    switch(action.type){
        case TOGGLE_NAVBAR:
            return {
                ...state,
                navbarOpen: state.navbarOpen ? false : true
            }
        default:
            return state
    }
}

export default navbarReducer