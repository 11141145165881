import React from 'react'
import {StyleRoot} from 'radium'
import {connect} from 'react-redux'
import {saveTeamData} from '../../../redux/actions/team/index'
import ScrollAnimation from 'react-animate-on-scroll';

const mapStateToProps = state => {
    return state
}

class ourTeam extends React.Component{

    constructor(props){
        super(props);
       
    }

    render(){
        return(
            <StyleRoot>
                
                <section className="team-aria">
                <ScrollAnimation animateIn='fadeIn'>
                <div className="container">
                    <div className="row">
                    <div className="col-sm-12 text-center">
                        <div className="section-title">
                        <p className="mb-0 text-uppercase iq-fw-5 iq-ls-3">Who Makes Clickficks Happen?</p>
                        <h2 className="title iq-fw-8">Dedicated Team</h2>
                        </div>
                    </div>
                    </div>
                    {typeof(this.props.TeamReducer.team) != 'undefined' ?
                        <div className="row">
                            {this.props.TeamReducer.team.map(t=> {
                               
                                return(
                                    <div>
                                        {t.teamMember === 1 ? //only display team members
                                            <div className="col-lg-4 col-md-6" key={`ourTeam-${t.id}`}>
                                                <div className="team-three">
                                                    <div className="team-bg">
                                                        <img className="img-fluid" src={t.img} alt="Tyler Clay Headshot" />
                                                        <div className="social-box">
                                                        <a href={t.facebook}><i className="fab fa-facebook-f" /></a>
                                                        <a href={t.twitter}><i className="fab fa-twitter" /></a>
                                                        <a href={t.linkedIn}><i className="fab fa-linkedin-in" /></a>
                                                        </div>
                                                    </div>
                                                    <div className="team-content text-center">
                                                        <h5><span>{t.fullName}</span></h5>
                                                        <p>{t.title}</p>
                                                    </div>
                                                </div>
                                            </div> : <div/>
                                        }
                                    </div>
                                )
                            })}
                            
                        </div> : <div/>
                    }
                </div>
                </ScrollAnimation>
                </section>
                
            </StyleRoot>
        )
    }
}

export default connect(mapStateToProps)(ourTeam)