import React from 'react'
import {StyleRoot} from 'radium'
import withStyles from 'react-jss'


const styles = {
    button:{
        color: '#7C60D5',
        background: 'white',
        borderRadius: '5px',
        border: '1px solid #7C60D5',
        marginTop: '20px',
        padding: '20px',
        fontSize: '1.3em',
        fontWeight: '500',
        cursor: 'pointer',
        '&:hover':{
            border: '1px solid #ffffff',
            background: '#7C60D5',
            color: 'white'
        }
    }
}
class clientFeedback extends React.Component{

    componentDidMount(){
        
    }

    loadScripts = () => {
       
    }
    componentWillUnmount(){
        
    }
    render(){
        const {classes} = this.props
        return(
            <StyleRoot>
                 <section className="main-bg testimonial-content">
                    <div className="container">
                        <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="section-title">
                            <p className="mb-0 text-uppercase iq-fw-5 iq-ls-3  text-white">Testimonials</p>
                            <h2 className="title iq-fw-8 text-white">Client Feedback</h2>
                            <a href='https://www.google.com/search?sxsrf=ACYBGNQJ8TRnOnGOPtpevQDVTjky1ZCe8g%3A1568591196389&source=hp&ei=XM1-XdXwFInSsAXcqKP4Dw&q=clickficks+software+%26+marketing&oq=clickficks+s&gs_l=psy-ab.3.0.35i39l2j0i13i30j0i13i10i30j0i13i30j0i13i10i30l2j0i13i30l3.1749.3856..4657...2.0..0.132.1203.8j4....2..0....1..gws-wiz.......0i131j0j0i10j0i13j0i13i10.Y-snrHW93VM#lrd=0x8640bf680541e5fb:0xba9971fd8a81a52,3,,,' target='__new' rel='nofollow'>
                                <button className={classes.button}>Leave Feedback</button>
                            </a>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        
                        </div>
                    </div>
                    </section>
            </StyleRoot>
        )
    }
}

export default withStyles(styles)(clientFeedback)

/*Client Feedback HTML
<div className="col-sm-12">
                            <div className="owl-carousel" data-autoplay="false" data-loop="true" data-nav="true" data-dots="false" data-items={2} data-items-laptop={2} data-items-tab={2} data-items-mobile={1} data-items-mobile-sm={1} data-margin={30}>
                            <div className="item">
                                <div className="testimonial-box white">
                                <img src="images/testimonials/01.jpg" className="img-fluid mb-3 img-shap" alt />
                                <p className="testimonial-info"> Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat. </p>
                                <h6 className="mb-3">Nik Jorden</h6>
                                <span><strong className="text-black">CEO -</strong><span className="iq-fw-4 ml-0"> Markethon</span></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-box white">
                                <img src="images/testimonials/02.jpg" className="img-fluid mb-3 img-shap" alt />
                                <p className="testimonial-info"> Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat. </p>
                                <h6 className="mb-3">Kips Leo</h6>
                                <span><strong className="text-black">CEO -</strong><span className="iq-fw-4 ml-0"> Markethon</span></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-box white">
                                <img src="images/testimonials/03.jpg" className="img-fluid mb-3 img-shap" alt />
                                <p className="testimonial-info"> Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat. </p>
                                <h6 className="mb-3">Glen Jax </h6>
                                <span><strong className="text-black">CEO -</strong><span className="iq-fw-4 ml-0"> Markethon</span></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-box white">
                                <img src="images/testimonials/04.jpg" className="img-fluid mb-3 img-shap" alt />
                                <p className="testimonial-info"> Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat. </p>
                                <h6 className="mb-3">Larry Page</h6>
                                <span><strong className="text-black">CEO -</strong><span className="iq-fw-4 ml-0"> Markethon</span></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-box white">
                                <img src="images/testimonials/05.jpg" className="img-fluid mb-3 img-shap" alt />
                                <p className="testimonial-info"> Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat. </p>
                                <h6 className="mb-3">JD Scot</h6>
                                <span><strong className="text-black">CEO -</strong><span className="iq-fw-4 ml-0"> Markethon</span></span>
                                </div>
                            </div>
                            </div>
                        </div>*/